// Typed based off profile-service business types
export enum BusinessType {
  SOLE_PROPRIETOR = "Sole Proprietor",
  PRIVATE_LIMITED_COMPANY = "Private Limited Company (PTY Ltd)",
  CLOSE_CORPORATION = "Close Corporation (CC)",
  INCORPORATED_COMPANY = "Incorporated Company (Inc)",
  NON_PROFIT_ORGANISATION = "Non-Profit Organisation",
  OTHER_LEGAL_ENTITIES = "Other Legal Entities",
  OTHER_LEGAL_PERSONS = "Other Legal Persons",
  PARTNERSHIP = "Partnership",
  TRUST = "Trust",
}
export const businessTypes: BusinessType[] = Object.values(BusinessType);

export function isBusinessType(x: string): x is BusinessType {
  return businessTypes.includes(x as BusinessType);
}

export function isBusinessTypeImplemented(x: string) {
  const implementedBusinessTypes = [BusinessType.SOLE_PROPRIETOR, BusinessType.PRIVATE_LIMITED_COMPANY]
  return implementedBusinessTypes.includes(x as BusinessType);
}
