import { FieldState, FormState } from "formstate";
import { AddressDetails, BankingDetails } from "./types";

export type FieldStateFromType<T> = {
  [K in keyof T]: FieldState<T[K]>;
};

// If there are nested objects, we assume those are FormStates
export type FormStateFromType<T> = {
  [K in keyof T]: T[K] extends object
    ? FormState<FieldStateFromType<T[K]>>
    : FieldState<T[K]>;
};

/**
 * TODO: Fix the typing `as`
 * @param input A formstate form
 * @returns The values of the form as an object
 */
export function reduceFormState<T extends BankingDetails | AddressDetails>(
  input: FormState<FieldStateFromType<T>>
): T {
  return Object.keys(input.$).reduce<T>((prev, field) => {
    const fieldTyped = field as keyof FormState<FieldStateFromType<T>>["$"];
    const value = input.$[fieldTyped].value;
    prev[fieldTyped] = value;
    return prev;
  }, {} as T);
}
