export type PutOverrideBody = {
  owner: string;
  verificationType: DataVerification;
  entityType: FicaTableEntity;
  entityId: string;
  status: string;
  comment: string;
  reason: string;
};

export enum STATUS {
  "APPROVED" = "APPROVED",
  "REJECTED" = "REJECTED",
}

export enum DataVerification {
  BANKING_DETAILS = "BankingDetails",
  IDENTITY = "Identity",
  ADDRESS = "Address",
  MATCH = "MATCH",
  SAFPS = "SAFPS",
  SANCTIONS = "Sanctions",
  SELFIE = "Selfie",
  DOCUMENT = "DocumentVerification",
  BUSINESS_VERIFICATION = "BusinessVerification"
}

export enum FicaTableEntity {
  PERSON = "Person",
  STORE = "Store",
  BANKACCOUNT = "BankAccount",
  BUSINESS = "Business",
}
