export * from "./auth";
export * from "./axios.base";
export * from "./types";

export const MerchantProfilePrefix = "Merchant-";

export const addMerchantPrefix = (str: string) =>
  `${MerchantProfilePrefix}${str}`;

export const removeMerchantPrefix = (str: string) =>
  str.replace(new RegExp(`^${MerchantProfilePrefix}`), "");

export const hasMerchantPrefix = (str: string) =>
  new RegExp(`^${MerchantProfilePrefix}`).test(str);

export const removeNonNumbers = (str: string) => str.replace(/[^0-9]/g, "");
