import { Container } from "@ikhokha/react-components";
import React, { useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  addMerchantPrefix,
  MerchantProfilePrefix,
  removeMerchantPrefix,
  removeNonNumbers,
} from "../common";
import MerchantCapture from "./Onboarding/MerchantCapture";
import { getFicaData } from "./Onboarding/MerchantCapture/requests";
import { FormMerchantData } from "./Onboarding/MerchantCapture/types";
import MerchantResults from "./Onboarding/MerchantResults";

/**
 * This helper functions ensures a merchant id used is valid
 * @link https://emergemobile.atlassian.net/browse/NF1-1637
 */
const isValidMerchantPattern = (str: string) => {
  const regex = new RegExp(`^${MerchantProfilePrefix}\\d+$`);
  return regex.test(str);
};

// TODO: Add Typescript magic to make paths and params type safe
// See: https://dev.to/0916dhkim/type-safe-usage-of-react-router-5c44
type TParams = { merchantId?: string };

const ShowMerchantCapture: React.FunctionComponent = () => {
  const { merchantId } = useParams<TParams>();

  if (merchantId && !isValidMerchantPattern(merchantId)) {
    alert(
      `Invalid merchant id in url, merchant is ${merchantId}, but should be like Merchant-8743`
    );
    throw new Error("Invalid merchant id");
  }

  const [initialState, setInitialState] = useState<Partial<FormMerchantData>>(
    {}
  );
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (
      merchantId: string | undefined
    ): Promise<
      | { success: true; data: Partial<FormMerchantData> }
      | { success: false }
      | undefined
    > => {
      if (merchantId) {
        try {
          const data: Partial<FormMerchantData> = await getFicaData(merchantId);
          return { success: true, data };
        } catch (error) {
          return { success: false };
        }
      }
      return { success: true, data: {} };
    };

    (async () => {
      setUserLoaded(false);
      let res = await fetchData(merchantId);
      if (res?.success) {
        setInitialState(res.data);
        setUserLoaded(true);
      }
    })();
  }, [merchantId]);

  return userLoaded ? (
    <MerchantCapture initialState={initialState} />
  ) : (
    <h2>Fetching Profile for: {merchantId}</h2>
  );
};

const OnboardingPage: React.FunctionComponent = () => {
  const [merchantId, setMerchantId] = useState<string>("");
  const path = useLocation().pathname;

  // FIXME: make checking of the merchantId possible different
  const checkMerchantId = (id: string): boolean => {
    if (!id.length) {
      alert("Please enter and existing merchant id");
      return false;
    }

    return true;
  };
  return (
    <div>
      <h3>Welcome to Fica Onboarding</h3>
      <h4>View Merchant</h4>
      Merchant-
      <input
        type="text"
        onChange={(e) => {
          setMerchantId(
            addMerchantPrefix(removeNonNumbers(e.currentTarget.value))
          );
        }}
        value={removeMerchantPrefix(merchantId)}
        placeholder="Merchant ID"
      ></input>
      <button
        onClick={() => {
          if (checkMerchantId(merchantId))
            window.location.href = `${path}/${merchantId}/results`;
        }}
      >
        Results
      </button>
      <button
        onClick={() => {
          if (checkMerchantId(merchantId))
            window.location.href = `${path}/${merchantId}`;
        }}
      >
        Merchant Information
      </button>
    </div>
  );
};

function AdminScreen({ match }: RouteComponentProps<TParams>) {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={`${match.path}/new`}
          component={ShowMerchantCapture}
        />
        <Route
          exact
          path={`${match.path}/:merchantId/results`}
          render={(props) => (
            <MerchantResults merchantId={props.match.params.merchantId} />
          )}
        />
        <Route
          path={`${match.path}/:merchantId`}
          component={ShowMerchantCapture}
        />
        <Route exact path={`${match.path}`} component={OnboardingPage} />
      </Switch>
    </Container>
  );
}

export default AdminScreen;
