import { Container, defaultTheme } from "@ikhokha/react-components";
import { Auth } from "aws-amplify";
import { AmplifyTheme, withAuthenticator } from "aws-amplify-react";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Admin from "./Admin";
import hamburger from "./assets/svg/hamburger.svg";
import logo from "./assets/svg/logo.svg";

function NotFound() {
  return (
    <h1>
      Page not found. Return to home page. <Link to="/">Home</Link>
    </h1>
  );
}

// Basic logout
async function signOut() {
  console.log("Logging out");
  try {
    Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

// Debug screen to check tokens and their data
const DebugLogin: React.FunctionComponent = () => {
  const [token, setToken] = useState("not set");

  useEffect(() => {
    const getJwtToken = async (): Promise<string> => {
      try {
        return new Promise((resolve, reject) => {
          return Auth.currentSession()
            .then((data) => {
              resolve(
                JSON.stringify({
                  jwt: data.getAccessToken().getJwtToken(),
                  refresh: data.getRefreshToken().getToken(),
                  id: data.getIdToken().getJwtToken(),
                  valid: data.isValid(),
                  decoded: data.getIdToken().decodePayload(),
                })
              );
            })
            .catch((error) => reject(error));
        });
      } catch (error) {
        return "error";
      }
    };

    (async () => {
      const token = await getJwtToken();
      setToken(token);
    })();
  }, []);

  return (
    <div>
      <p>Welcome to the debug screen</p>
      <p>Token Data</p>
      <textarea value={token}></textarea>
      <button onClick={signOut}>SignOut</button>
    </div>
  );
};

const NavBar: React.FunctionComponent = () => {
  // Allow us to either give a route to path to or a onclick handler  to call an action instead of routing
  // [ButtonClick handler or "Route", "Test To Display"]
  const links: [
    string | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    string
  ][] = [
    ["/admin", "Admin Page"],
    [signOut, "Logout"],
  ];
  const linkStyle: React.CSSProperties = {
    textDecoration: "none",
    color: "white",
    padding: "0  10px",
  };
  return (
    <div style={{ backgroundColor: "#141414" }}>
      <Container>
        <nav
          style={{
            justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center",
          }}
          className="navbar"
        >
          <Link to="/">
            <img style={{ height: "40px" }} src={logo} alt="logo" />
          </Link>
          <button style={{ display: "none" }} className="navbar-toggler">
            <img src={hamburger} alt="hamburger" />
          </button>
          <div className="navbar-list">
            {links.map(([pathOrHandler, text], index) => {
              return typeof pathOrHandler === "function" ? (
                <button onClick={pathOrHandler}>{text}</button>
              ) : (
                <Link
                  key={`nav-link-${index + 1}`}
                  style={linkStyle}
                  to={pathOrHandler}
                >
                  {text}
                </Link>
              );
            })}
          </div>
        </nav>
      </Container>
    </div>
  );
};

const Home: React.FunctionComponent = () => {
  return (
    <Container>
      <h1>Welcome to the home page</h1>
    </Container>
  );
};

function AppRouter() {
  return (
    <Router>
      <NavBar />
      <Switch>
        {/* Make our default home root our admin page */}
        <Route exact path="/" render={() => <Redirect to="/admin" />} />
        <Route exact path="/home" component={Home} />
        <Route path="/admin" component={Admin} />
        <Route path="/debugLogin" component={DebugLogin} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

// Add the default amplify theme with some ikhokha colour overrides
const myTheme: typeof AmplifyTheme = {
  ...AmplifyTheme,
  sectionHeader: {
    ...AmplifyTheme.sectionHeader,
    backgroundColor: defaultTheme.colors.primary.yellow,
    color: defaultTheme.colors.primary.black,
  },
};

// AWS Amplify does a bunch of magic to wrap this app in an authentication component. If we need authed and un-authed screens we will need to refactor this.
// Right now im locking down the entire app as its for internal staff only and I dont want to surface any data to people/bots/public
export default withAuthenticator(AppRouter, false, [], null, myTheme);
