import { Validator } from "formstate";
import { AdminGetDataResponse, BankAccount, ReturnedData, Verification } from "./requests";
import { STATUS, ValueOf } from "./types";
import { devLogging } from "../../../common/utils";

export const isValidIdNumberValidator: Validator<string> = (
  idNumber: string
) => {
  // check that value submitted is a number
  if (isNaN(parseInt(idNumber))) {
    return "Value supplied is not a valid number";
  }

  // check length of 13 digits
  if (idNumber.length !== 13) {
    return "Number supplied does not have 13 digits";
  }

  // check that YYMMDD group is a valid date
  let yy = parseInt(idNumber.substring(0, 2)),
    mm = parseInt(idNumber.substring(2, 4)),
    dd = parseInt(idNumber.substring(4, 6));

  const dob = new Date(yy, mm - 1, dd);

  // check values - add one to month because Date() uses 0-11 for months
  if (
    !(
      (dob.getFullYear() + "").substring(2, 4) ===
        yy.toString().padStart(2, "0") &&
      dob.getMonth() === mm - 1 &&
      dob.getDate() === dd
    )
  ) {
    return "Date in first 6 digits is invalid. Should be a date";
  }

  // ensure third to last digit is a 1 or a 0
  if (parseInt(idNumber.substring(10, 11)) > 1) {
    return (
      "Third to last digit can only be a 0 or 1 but is a " +
      idNumber.substring(10, 11)
    );
  }

  // ensure second to last digit is a 8 or a 9
  if (parseInt(idNumber.substring(11, 12)) < 8) {
    return (
      "Second to last digit can only be a 8 or 9 but is a " +
      idNumber.substring(11, 12)
    );
  }

  // calculate check bit (Z) using the Luhn algorithm
  var ncheck = 0,
    beven = false;

  for (var c = idNumber.length - 1; c >= 0; c--) {
    var cdigit = idNumber.charAt(c),
      ndigit = parseInt(cdigit, 10);

    if (beven) {
      if ((ndigit *= 2) > 9) ndigit -= 9;
    }

    ncheck += ndigit;
    beven = !beven;
  }

  if (ncheck % 10 !== 0) {
    return "Invalid ID. Checksum is incorrect";
  }

  return "";
};

/**
 * Make the first letter uppercase of the give string
 */
export const titleCase = (str: string): string => {
  const [firstChar, ...rest] = str.split("");
  return `${firstChar.toUpperCase()}${rest.join("")}`;
};

export function handleNetworkError(
  error: unknown,
  reject: (reason?: any) => void
) {
  console.error("Error", JSON.stringify(error));
  alert(`There was a network error. Check logs: ${JSON.stringify(error)}`);
  reject(error);
}

export const sortInDescByModifiedAt = (arrayToSort: any[]) => {
  arrayToSort.sort((a: any, b: any) => {
    const modifiedAt1 = new Date(a?.modifiedAt).getTime();
    const modifiedAt2 = new Date(b?.modifiedAt).getTime();

    return modifiedAt2 - modifiedAt1;
  });
};

export const getAllVericationsForEntity = (
  entityData?: ValueOf<AdminGetDataResponse>
) => {
  if (Array.isArray(entityData)) {
    const allVerications = [];
    entityData.map((data) => {
      if (data.verifications.length) {
        allVerications.push(...data.verifications);
      }
      return data.verifications;
    });
    return allVerications;
  }

  if (Object.keys(entityData).length) {
    return entityData.verifications;
  }

  return [];
};

export const getVerificationsByStatus = (
  verications: Verification[],
  status: STATUS
) => {
  const filteredVerifications = verications.filter((verification) => {
    return verification.status === status;
  });

  return filteredVerifications;
};

export const getEntitiesByStatus = (
  entityData: ValueOf<AdminGetDataResponse>,
  status: STATUS
) => {

  if(Array.isArray(entityData)){
    const filteredEntities: ValueOf<Omit<AdminGetDataResponse, 'business'>> = (entityData as any).filter((data) => {
      return data.status === status;
    });
    
    if(!filteredEntities.length){
      return
    }

    devLogging('Utils -> getEntitiesByStatus -> filteredEntities',filteredEntities);

    return filteredEntities
  }

  if(entityData?.status === status){
    return entityData;
  }
};

export const getLastestEntityByStatus = (entityData: ValueOf<AdminGetDataResponse>, status: STATUS) => {
  const retrievedEntityDataByStatus = getEntitiesByStatus(
    entityData,
    status
  )

  if (!retrievedEntityDataByStatus) {
    return
  }

  if(Array.isArray(retrievedEntityDataByStatus)){
    sortInDescByModifiedAt(retrievedEntityDataByStatus);
    devLogging('Utils -> getLastestEntityByStatus -> retrievedEntityDataByStatus',retrievedEntityDataByStatus);
    return retrievedEntityDataByStatus[0];
  }

  return retrievedEntityDataByStatus;
}

export const getLastestRejectedVerication = (verications: Verification[]) => {
  const rejectedVerifications = getVerificationsByStatus(
    verications,
    STATUS.REJECTED
  );

  if (rejectedVerifications.length) {
    sortInDescByModifiedAt(rejectedVerifications);

    return rejectedVerifications[0];
  }
};

export const getLastestApprovedVerication = (verications: Verification[]) => {
  const approvedVerifications = getVerificationsByStatus(
    verications,
    STATUS.APPROVED
  );

  if (approvedVerifications.length) {
    sortInDescByModifiedAt(approvedVerifications);

    return approvedVerifications[0];
  }
};

export const isAllEntityIdsPresent = (entities: AdminGetDataResponse) => {
  return (
    entities.persons[0]?.id &&
    entities.stores[0]?.id &&
    entities.bankAccounts[0]?.id &&
    entities.business?.id
  );
};

export const isSomeEntityIdsPresent = (entities: AdminGetDataResponse) => {
  return (
    entities.persons[0]?.id ||
    entities.stores[0]?.id ||
    entities.bankAccounts[0]?.id ||
    entities.business.id
  );
};

export const getAllEntityIds = (entities: AdminGetDataResponse) => {
  return {
    bankAccount: getBankFromResponseByStatus(entities.bankAccounts)?.id,
    business: entities.business?.id,
    person: entities.persons[0]?.id,
    store: entities.stores[0]?.id,
  }
}

export const getBankFromResponseByStatus = (bankAccounts: ReturnedData<BankAccount>[]) => {
  const bank = bankAccounts;
  if(!bank?.length){
    return
  }

  //Switch statement would be better in the future
  const lastestApprovedBank = getLastestEntityByStatus(bankAccounts, STATUS.APPROVED);

  if(lastestApprovedBank){
    return lastestApprovedBank as ReturnedData<BankAccount>
  }

  const lastestRejectedBank = getLastestEntityByStatus(bankAccounts, STATUS.REJECTED);

  if(lastestRejectedBank){
    return lastestRejectedBank as ReturnedData<BankAccount>
  }

  const lastestPendingBank = getLastestEntityByStatus(bankAccounts, STATUS.PROCESSING_PENDING);

  if(lastestPendingBank){
    return lastestPendingBank as ReturnedData<BankAccount>;
  }
}