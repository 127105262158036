import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import {
  accountTypes,
  Bank,
  bankLookup,
  isBank,
} from "../../../../common/constants/banking";
import { FieldStateFromType } from "../formState";
import { BankingDetails } from "../types";
import { titleCase } from "../utils";

type BankingDetailsFields = FieldStateFromType<BankingDetails>;

const bankingDetailsInputPrettyNames: Record<
  keyof FormState<BankingDetailsFields>["$"],
  string
> = {
  accountHolder: "Account Holder",
  accountNumber: "Account Number",
  accountType: "Account Type",
  bank: "Bank",
  branchCode: "Branch Code",
};

type BankAccountSectionProps = {
  form: FormState<FieldStateFromType<BankingDetails>>;
  errorStyle: React.CSSProperties;
};

export function createBankAccountState(
  initial: Partial<BankingDetails> | undefined
): FormState<BankingDetailsFields> {
  return new FormState<BankingDetailsFields>({
    accountHolder: new FieldState<string>(
      initial?.accountHolder || ""
    ).validators((val) => !val && "Should not be empty"),
    accountNumber: new FieldState(initial?.accountNumber || "").validators(
      (val) => !val && "Should not be empty"
    ),
    accountType: new FieldState<string>(initial?.accountType || "").validators(
      (val) => !accountTypes.includes(val) && `Please choose an account type`
    ),
    bank: new FieldState<Bank | string>(initial?.bank || "").validators(
      (val) => !isBank(val) && "Please choose a bank"
    ),
    branchCode: new FieldState(initial?.branchCode || "").validators(
      (val) => !val && "Should not be empty"
    ),
  });
}

@observer
export class BankAccountSection extends React.Component<BankAccountSectionProps> {
  @observable
  private form: FormState<FieldStateFromType<BankingDetails>>;

  private errorStyle: React.CSSProperties;

  constructor(props: BankAccountSectionProps) {
    super(props);

    this.form = props.form;
    this.errorStyle = props.errorStyle;

    makeObservable(this);
  }

  render() {
    return (
      <div>
        <h4>Banking details</h4>
        {Object.keys(this.form.$).map((unTypedField) => {
          const field =
            unTypedField as keyof FormState<BankingDetailsFields>["$"];

          const readOnlyFields: Array<
            keyof FormState<BankingDetailsFields>["$"]
          > = ["branchCode"];

          switch (field) {
            case "bank":
            case "accountType":
              // Generate a select menu if its a bank selection or account type selection
              const options =
                field === "bank" ? Object.keys(Bank) : accountTypes;
              return (
                <div key={`bankingDetails-inputGroup-${field}`}>
                  <label htmlFor={`bankingDetails-${field}`}>
                    {bankingDetailsInputPrettyNames[field]}
                  </label>
                  <select
                    name={`bankingDetails-${field}`}
                    value={this.form.$[field].value}
                    disabled={true}
                    onChange={(e) => {
                      if (field === "bank") {
                        this.form.$.branchCode.value = isBank(e.target.value)
                          ? bankLookup[e.target.value].branchCode
                          : "";
                      }
                      this.form.$[field].onChange(e.target.value);
                    }}
                  >
                    {field === "bank"
                      ? options.map((opt) => (
                          <option value={opt}>
                            {bankLookup[opt as Bank].label}
                          </option>
                        ))
                      : options.map((opt) => (
                          <option value={opt}>{titleCase(opt)}</option>
                        ))}

                    <option value="">Choose One</option>
                  </select>
                  <p style={this.errorStyle}>{this.form.$[field].error}</p>
                </div>
              );
            default:
              return (
                <div key={`bankingDetails-inputGroup-${field}`}>
                  <label htmlFor={`bankingDetails-${field}`}>
                    {bankingDetailsInputPrettyNames[field]}
                  </label>
                  <input
                    name={`bankingDetails-${field}`}
                    type="text"
                    value={this.form.$[field].value}
                    onChange={(e) =>
                      this.form.$[field].onChange(e.target.value)
                    }
                    disabled={true}
                    readOnly={readOnlyFields.includes(field)}
                    placeholder={field === "branchCode" ? "Choose a bank" : ""}
                  />
                  <p style={this.errorStyle}>{this.form.$[field].error}</p>
                </div>
              );
          }
        })}
      </div>
    );
  }
}
