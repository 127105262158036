import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "@ikhokha/react-components";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import { userPoolDetails } from "./common/config";
import { hotjar } from "react-hotjar";
import {
  getEnvValueOrError,
  convertIntStringToIntNumber,
} from "./common/utils";

// TODO: Make init scripts prettier

// Setup our Amplify configuration
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    ...userPoolDetails,
  },
});

// Setup Hotjar
// Hotjar is not required functionality and therefore fails silently in production if it fails to init
try {
  /**
   * Config for react-hotjar
   * @see https://www.npmjs.com/package/react-hotjar
   */
  const [
    // hjid: Stands for 'Hotjar ID' - Your site's ID. This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.
    hjid,
    //hjsv: Stands for 'Hotjar Snippet Version' - The version of the Tracking Code you are using. This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones. Knowing which version your site includes allows hotjar team to contact you and inform you accordingly
    hjsv,
  ]: [number, number] = [
    convertIntStringToIntNumber(getEnvValueOrError("REACT_APP_HOTJAR_ID")),
    convertIntStringToIntNumber(getEnvValueOrError("REACT_APP_HOTJAR_VER")),
  ];

  if(process.env.REACT_APP_ENV === "prod"){
    hotjar.initialize(hjid, hjsv);
  }
} catch (e) {
  // Only log the error if not in production
  if (process.env["REACT_APP_ENV"] !== "prod") {
    console.error("Error initializing HotJar", e);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
