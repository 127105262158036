type BankDetails = {
  label: string;
  branchCode: string;
};

export enum Bank {
  ABSA = "ABSA",
  AFRICAN_BANK = "AFRICAN_BANK",
  AL_BARAKA_BANK = "AL_BARAKA_BANK",
  BANK_ZERO = "BANK_ZERO",
  BIDVEST_BANK = "BIDVEST_BANK",
  BNP_PARIBAS = "BNP_PARIBAS",
  BOE = "BOE",
  CAPITEC_BANK = "CAPITEC_BANK",
  DISCOVERY_BANK = "DISCOVERY_BANK",
  FINBOND_MUTUAL_BANK = "FINBOND_MUTUAL_BANK",
  FNB = "FNB",
  FIRST_RAND_BANK = "FIRST_RAND_BANK",
  GRINDROD_BANK = "GRINDROD_BANK",
  HABIB_OVERSEAS_BANK = "HABIB_OVERSEAS_BANK",
  HBZ_BANK_LTD = "HBZ_BANK_LTD",
  HSBC = "HSBC",
  IMB = "IMB",
  INVESTEC_BANK = "INVESTEC_BANK",
  ITHALA = "ITHALA",
  JP_MORGAN_CHASE = "JP_MORGAN_CHASE",
  MERCANTILE = "MERCANTILE",
  NEDBANK = "NEDBANK",
  OLD_MUTUAL_BANK = "OLD_MUTUAL_BANK",
  OLYMPUS = "OLYMPUS",
  PEP_BANK = "PEP_BANK",
  POSTBANK = "POSTBANK",
  RMB = "RMB",
  SASFIN = "SASFIN",
  SOCIETE_GENERALE = "SOCIETE_GENERALE",
  SOUTH_AFRICAN_BANK_O = "SOUTH_AFRICAN_BANK_O",
  STD_BANK = "STD_BANK",
  STATE_BANK_OF_INDIA = "STATE_BANK_OF_INDIA",
  TEBA_BANK = "TEBA_BANK",
  TYME_BANK = "TYME_BANK",
  UBANK_LTD = "UBANK_LTD",
  UNIBANK = "UNIBANK",
  VBS_MUTUAL_BANK = "VBS_MUTUAL_BANK",
}

export const bankLookup: Record<Bank, BankDetails> = {
  ABSA: {
    label: "Absa Bank Limited",
    branchCode: "632005",
  },
  AFRICAN_BANK: {
    label: "African Bank Limited",
    branchCode: "430000",
  },
  AL_BARAKA_BANK: {
    label: "Al Baraka Islamic Bank",
    branchCode: "800000",
  },
  BANK_ZERO: {
    label: "Bank Zero",
    branchCode: "888000",
  },
  BIDVEST_BANK: {
    label: "Bidvest Bank Limited",
    branchCode: "462005",
  },
  BNP_PARIBAS: {
    label: "BNP Paribas",
    branchCode: "688000",
  },
  BOE: {
    label: "Nedbank Ltd Incorp. BOE Bank",
    branchCode: "440810",
  },
  CAPITEC_BANK: {
    label: "Capitec Bank Limited",
    branchCode: "470010",
  },
  DISCOVERY_BANK: {
    label: "Discovery Bank Limited",
    branchCode: "679000",
  },
  FINBOND_MUTUAL_BANK: {
    label: "Finbond Mutual Bank",
    branchCode: "",
  },
  FNB: {
    label: "First National Bank (FNB)",
    branchCode: "250655",
  },
  FIRST_RAND_BANK: {
    label: "FirstRand Bank - A subsidiary of First Rand Limited",
    branchCode: "250655",
  },
  GRINDROD_BANK: {
    label: "Grindrod Bank Limited",
    branchCode: "223626",
  },
  HABIB_OVERSEAS_BANK: {
    label: "Habib Overseas Bank Limited",
    branchCode: "",
  },
  HBZ_BANK_LTD: {
    label: "Habib Bank Zurich Ltd",
    branchCode: "",
  },
  HSBC: {
    label: "HSBC",
    branchCode: "587000",
  },
  IMB: {
    label: "IMB Financial Services (PTY) Ltd",
    branchCode: "",
  },
  INVESTEC_BANK: {
    label: "Investec Bank Limited",
    branchCode: "580105",
  },
  ITHALA: {
    label: "Ithala Bank",
    branchCode: "",
  },
  JP_MORGAN_CHASE: {
    label: "JP Morgan Chase Bank",
    branchCode: "432000",
  },
  MERCANTILE: {
    label: "Mercantile Bank Limited",
    branchCode: "450105",
  },
  NEDBANK: {
    label: "Nedbank Limited",
    branchCode: "198765",
  },
  OLD_MUTUAL_BANK: {
    label: "Old Mutual",
    branchCode: "462005",
  },
  OLYMPUS: {
    label: "Olympus Mobile Bank",
    branchCode: "585001",
  },
  PEP_BANK: {
    label: "PEP Bank (Nedbank)",
    branchCode: "400001",
  },
  POSTBANK: {
    label: "SA Post Bank (Post Office)",
    branchCode: "460005",
  },
  RMB: {
    label: "Rand Merchant Bank",
    branchCode: "",
  },
  SASFIN: {
    label: "Sasfin Bank Limited",
    branchCode: "683000",
  },
  SOCIETE_GENERALE: {
    label: "Societe Generale",
    branchCode: "",
  },
  SOUTH_AFRICAN_BANK_O: {
    label: "South African Bank of Athens",
    branchCode: "410506",
  },
  STD_BANK: {
    label: "Standard Bank of South Africa",
    branchCode: "051001",
  },
  STATE_BANK_OF_INDIA: {
    label: "State Bank of India",
    branchCode: "801000",
  },
  TEBA_BANK: {
    label: "Teba Bank",
    branchCode: "",
  },
  TYME_BANK: {
    label: "Tyme Bank",
    branchCode: "678910",
  },
  UBANK_LTD: {
    label: "Ubank Ltd",
    branchCode: "431010",
  },
  UNIBANK: {
    label: "Unibank",
    branchCode: "",
  },
  VBS_MUTUAL_BANK: {
    label: "VBS Mutual Bank",
    branchCode: "588000",
  },
};

export function isBank(x: string): x is Bank {
  return !!bankLookup[x as Bank];
}

// Use for form mapping as well as generating select options
// FIXME: Standardize mappings
export const accountTypes = [
  "Cheque Account",
  "Current Account",
  "Transmission",
  "Savings",
];
export const accountTypeMap: Record<string, string> = {
  CURRENT: "Current Account",
  SAVINGS: "Savings",
  Transmission: "Transmission",
  "Cheque Account": "Cheque Account",
  "Current Account": "Current Account",
  Current: "Current Account",
  Savings: "Savings",
}

// FIXME: Temp fica to profile known bank map
// This mapping is based off migration
export const ficaToProfileBankMap: Record<string, Bank> = {
  "Absa Bank Limited": Bank.ABSA,
  "African Bank Limited": Bank.AFRICAN_BANK,
  "Bidvest Bank Limited": Bank.BIDVEST_BANK,
  "Capitec Bank Limited": Bank.CAPITEC_BANK,
  "Discovery Bank Limited": Bank.DISCOVERY_BANK,
  "First National Bank (FNB)": Bank.FNB,
  "FirstRand Bank - A subsidiary of First Rand Limited": Bank.FIRST_RAND_BANK,
  "Grindrod Bank Limited": Bank.GRINDROD_BANK,
  "Investec Bank Limited": Bank.INVESTEC_BANK,
  "Mercantile Bank Limited": Bank.MERCANTILE,
  "Nedbank Limited": Bank.NEDBANK,
  "Old Mutual": Bank.OLD_MUTUAL_BANK,
  "Sasfin Bank Limited": Bank.SASFIN,
  "Standard Bank of South Africa": Bank.STD_BANK,
  "SA Post Bank (Post Office)": Bank.POSTBANK,
  "Tyme Bank": Bank.TYME_BANK,
};
