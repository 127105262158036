import { PutOverrideBody } from ".";
import { $axios } from "../common";

export class FicaService {
  public static async overrideVerification(override: PutOverrideBody) {
    return $axios.put(
      `/owners/${override.owner}/verifications/override`,
      override
    );
  }
}
