import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import FullScreenSpinner from "../Admin/common/FullScreenSpinner";
import { FormStateFromType } from "../Admin/Onboarding/MerchantCapture/formState";
import { Verification } from "../Admin/Onboarding/MerchantCapture/requests";
import { FicaService, FicaTableEntity, PutOverrideBody, STATUS } from "../api";
import { RecursivePartial } from "../types";

type VerificationProps = Pick<
  Verification,
  "owner" | "entityId" | "type" | "id" | "status"
> & { entityType: FicaTableEntity; refresh: () => void };

@observer
export class Override extends React.Component<VerificationProps> {
  @observable
  private show: boolean;
  private reasons: string[];
  @observable
  private isSubmitting: boolean;

  private formData?: RecursivePartial<PutOverrideBody>;

  constructor(props: VerificationProps) {
    super(props);
    this.show = false;
    this.isSubmitting = false;
    this.reasons = [
      "Incorrect Results",
      "System Error",
      "Manual Check Conducted",
    ];
    makeObservable(this);
  }

  form = new FormState<FormStateFromType<PutOverrideBody>>({
    comment: new FieldState(this.formData?.comment || ""),
    entityId: new FieldState(this.props.entityId),
    entityType: new FieldState(this.props.entityType),
    owner: new FieldState(this.props.owner),
    reason: new FieldState(this.formData?.reason || "").validators(
      (val) => !val && "Override reason is required"
    ),
    status: new FieldState(this.formData?.status || "").validators(
      (val) => !val && "Override status is required"
    ),
    verificationType: new FieldState(this.props.type),
  });

  handleClose = () => {
    this.show = false;
  };
  handleShow = () => {
    this.show = true;
  };

  onSubmit = async (): Promise<void> => {
    const valid = await this.form.validate();
    if (valid.hasError) {
      return;
    }

    this.isSubmitting = true;
    try {
      const payload: PutOverrideBody = {
        comment: this.form.$.comment.value,
        entityId: this.form.$.entityId.value,
        entityType: this.form.$.entityType.value,
        owner: this.form.$.owner.value,
        reason: this.form.$.reason.value,
        status: this.form.$.status.value,
        verificationType: this.form.$.verificationType.value,
      };
      await FicaService.overrideVerification(payload);
      this.isSubmitting = false;
      this.handleClose();
      // FIXME: Not sure how to propergate the results from overrideVerification() to the root parent to cause all children to render
      this.props.refresh();
    } catch (err) {
      alert("There was a network error. Check logs");
      console.log(JSON.stringify(err));
      this.isSubmitting = false;
    }
  };

  render(): React.ReactNode {
    const StyledModal = styled.div<{ display: string }>`
    hr {
      border: 1px solid #ddd;
      margin-bottom: 0;
      margin-top: 0;
    }
    
    .inputGroup > label {
        margin-right: 15px;
        width: 160px;
        display: inline-block;
    }
    .inputGroup > select, .inputGroup > textarea {
      width: 50%;
    }
    .header {
      padding-left: 10px;
      text-align: start;
    }
    .footer {
      padding: 15px;
      text-align: end;
    }
    .footer > button {
      margin-left: 10px;
    }
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
    }
    .modal-main {
      position: fixed;
      background: white;
      width: 45%;
      height: 500px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px 0;
      text-align: left;
      border-radius: 10px;
    }
    .modal-content {
      padding: 20px;
      overflow-y: scroll;
      height: 65%;
    }
    .display-modal {
      display: ${(props) => props.display}
    }
    }`;
    return (
      <div>
        <button className="ik-button" onClick={this.handleShow}>
          Override
        </button>
        <StyledModal display={this.show ? "block" : "none"}>
          <div className="modal display-modal">
            <div className="modal-main">
              <div className="header">
                <h3>Override {this.props.type}</h3>
              </div>
              <hr />
              <div className="modal-content">
                {this.show && (
                  <form>
                    <div className="inputGroup">
                      <label htmlFor={`overrideStatus`}>Override Status</label>
                      <select
                        name="status"
                        value={this.form.$.status.value}
                        onChange={(e) => {
                          this.form.$.status.onChange(e.target.value as STATUS);
                        }}
                      >
                        {Object.keys(STATUS).map((item: string, i: number) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                        <option value="">Choose One</option>
                      </select>
                      <p style={{ color: "red" }}>{this.form.$.status.error}</p>
                    </div>
                    <br />
                    <div className="inputGroup">
                      <label htmlFor={`overrideReason`}>Override Reason</label>
                      <select
                        name="reason"
                        value={this.form.$.reason.value}
                        onChange={(e) => {
                          this.form.$.reason.onChange(e.target.value);
                        }}
                      >
                        {this.reasons.map((item: string, i: number) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                        <option value="">Choose One</option>
                      </select>
                      <p style={{ color: "red" }}>{this.form.$.reason.error}</p>
                    </div>
                    <br />

                    <div className="inputGroup">
                      <label htmlFor={`overrideDetails`}>
                        Override Details
                      </label>
                      <textarea
                        cols={4}
                        rows={6}
                        name="comment"
                        value={this.formData?.comment}
                        onChange={action(
                          (e) => (this.form.$.comment.value = e.target.value)
                        )}
                      ></textarea>

                      {/* <p style={errorStyle}>{data.businessType.error}</p> */}
                    </div>
                    {this.isSubmitting && (
                      <FullScreenSpinner message="Submitting..." />
                    )}
                  </form>
                )}
              </div>
              <hr />
              <div className="footer">
                <button type="button" onClick={this.handleClose}>
                  Cancel
                </button>
                <button type="button" onClick={this.onSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </StyledModal>
      </div>
    );
  }
}
