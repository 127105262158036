import { getEnvValueOrError } from "./utils";

/**
 * Base URL for our micro systems
 * @example https://ikhokha.blue
 */
export const baseApiUrl = getEnvValueOrError("REACT_APP_IK_SERVICES_BASE_URL");

/**
 * The user pool details
 */
export const userPoolDetails: {
  /**
   * @example af-south-1
   */
  region: string;
  /**
   * @example af-south-1_3UTUS739y
   */
  userPoolId: string;
  /**
   * @example 1frg4aemp0gheqga550vi6v31i
   */
  userPoolWebClientId: string;
} = {
  userPoolId: getEnvValueOrError("REACT_APP_COGNITO_USER_POOL_ID"),
  userPoolWebClientId: getEnvValueOrError(
    "REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID"
  ),
  region: getEnvValueOrError("REACT_APP_COGNITO_REGION"),
};
