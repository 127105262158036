import styled from "styled-components";
import React from "react";
import { defaultTheme } from "@ikhokha/react-components";

const FullScreenSpinner: React.FunctionComponent<{ message: string }> = ({
  message,
}) => {
  const MainOverLay = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  `;

  const SpinnerHolder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -75px 0 0 -75px;
    width: 150px;
    height: 150px;
    border: 3px solid black;
    border-radius: 5px;
    background-color: #fdfdfd;
    text-align: center;

    svg {
      animation: rotate 2s linear infinite;
      z-index: 2;
      width: 50px;

      & circle {
        stroke: ${defaultTheme.colors.primary.yellow};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  `;

  return (
    <MainOverLay>
      <SpinnerHolder>
        <p>{message}</p>
        <svg viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </SpinnerHolder>
    </MainOverLay>
  );
};

export default FullScreenSpinner;
