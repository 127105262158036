import { FieldState, FormState } from "formstate";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FieldStateFromType } from "../formState";
import { AddressDetails } from "../types";
import { titleCase } from "../utils";

type AddressDetailsFields = FieldStateFromType<AddressDetails>;

const addressInputPrettyNames: Record<
  keyof FormState<AddressDetailsFields>["$"],
  string
> = {
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  suburb: "Suburb",
  city: "City",
  province: "Province",
  postalCode: "Postal Code",
};

export enum Province {
  EASTERN_CAPE = "Eastern Cape",
  FREE_STATE = "Free State",
  GAUTENG = "Gauteng",
  KWAZULU_NATAL = "KwaZulu-Natal",
  LIMPOPO = "Limpopo",
  MPUMALANGA = "Mpumalanga",
  NORTHERN_CAPE = "Northern Cape",
  NORTH_WEST = "North West",
  WESTERN_CAPE = "Western Cape",
}

const provinces = [
  "Eastern Cape",
  "Free State",
  "Gauteng",
  "KwaZulu-Natal",
  "Limpopo",
  "Mpumalanga",
  "Northern Cape",
  "North West",
  "Western Cape",
];

type AddressSectionProps = {
  name: string;
  form: FormState<FieldStateFromType<AddressDetails>>;
  errorStyle: React.CSSProperties;
};

export function createAddressState(
  initial: Partial<AddressDetails> | undefined
): FormState<AddressDetailsFields> {
  return new FormState<AddressDetailsFields>({
    addressLine1: new FieldState(initial?.addressLine1 || "").validators(
      (val) => !val && "Address Line 1 should be provided"
    ),
    addressLine2: new FieldState(initial?.addressLine2 || "").validators(),
    suburb: new FieldState(initial?.suburb || "").validators(
      (val) => !val && "Suburb should be provided"
    ),
    city: new FieldState(initial?.city || "").validators(
      (val) => !val && "City should be provided"
    ),
    province: new FieldState(initial?.province || "").validators(
      (val) => !provinces.includes(val) && `Please choose a province`
    ),
    postalCode: new FieldState(initial?.postalCode || "").validators(
      (val) => !/^[\d]{4}$/.test(val) && "Should be a 4 digit postal code"
    ),
  });
}

@observer
export class AddressSection extends React.Component<AddressSectionProps, {}> {
  @observable
  private form: FormState<FieldStateFromType<AddressDetails>>;

  private errorStyle: React.CSSProperties;
  private name: string;

  constructor(props: AddressSectionProps) {
    super(props);

    this.form = props.form;
    this.errorStyle = props.errorStyle;
    this.name = props.name;

    makeObservable(this);
  }

  render() {
    return (
      <div key={`addressDetails-${this.name}`}>
        {Object.keys(this.form.$).map((unTypedField) => {
          const field =
            unTypedField as keyof FormState<AddressDetailsFields>["$"];
          switch (field) {
            case "province":
              return (
                <div key={`addressDetails-inputGroup-${field}`}>
                  <label htmlFor={`addressDetails-${field}`}>
                    {addressInputPrettyNames[field]}
                  </label>
                  <select
                    name={`addressDetails-${field}`}
                    value={this.form.$[field].value}
                    disabled={true}
                    onChange={(e) =>
                      this.form.$[field].onChange(e.target.value)
                    }
                  >
                    {provinces.map((province) => (
                      <option value={province}>{titleCase(province)}</option>
                    ))}
                    <option value="">Choose One</option>
                  </select>
                  <p style={this.errorStyle}>{this.form.$[field].error}</p>
                </div>
              );
            default:
              return (
                <div key={`addressDetails-inputGroup-${field}`}>
                  <label htmlFor={`addressDetails-${field}`}>
                    {addressInputPrettyNames[field]}
                  </label>
                  <input
                    name={`addressDetails-${field}`}
                    type="text"
                    value={this.form.$[field].value}
                    disabled={true}
                    onChange={(e) =>
                      this.form.$[field].onChange(e.target.value)
                    }
                  />
                  <p style={this.errorStyle}>{this.form.$[field].error}</p>
                </div>
              );
          }
        })}
      </div>
    );
  }
}
